export class GoAffProCookies {
   private cookieDuration = 7 * 24 * 60 * 60;

   private refCodeCookieName = 'gap_ref_code';
   private visitorIdCookieName = 'gap_vid';

   getReferralCode() {
      return this.getCookieValue(this.refCodeCookieName);
   }

   getVisitorId() {
      return this.getCookieValue(this.visitorIdCookieName);
   }

   setReferralCode(referralCode: string) {
      return this.setCookie(this.refCodeCookieName, referralCode);
   }

   setVisitorId(visitorId: string) {
      return this.setCookie(this.visitorIdCookieName, visitorId);
   }

   removeCookies() {
      this.removeCookie(this.refCodeCookieName);
      this.removeCookie(this.visitorIdCookieName);
   }

   private getCookieValue(name: string): string | null {
      const regex = new RegExp(`(^| )${name}=([^;]+)`);
      const match = document.cookie.match(regex);
      const value = match ? match[2] : null;
      return value || null;
   }

   // https://vercel.com/guides/can-i-set-a-cookie-from-my-vercel-project-subdomain-to-vercel-app
   private getDomains() {
      const hostname = window.location.hostname;
      const allSubDomains = hostname.replace(/^([^.]+)\.(.*)$/, '$2');

      return [allSubDomains, hostname];
   }

   private setCookie(name: string, value: string) {
      this.getDomains().forEach(domain => {
         document.cookie = makeCookieString(name, domain, this.cookieDuration, value);
      });
   }

   private removeCookie(name: string) {
      this.getDomains().forEach(domain => {
         document.cookie = makeCookieString(name, domain, 0, '');
      });
   }
}

function makeCookieString(name: string, domain: string, maxAge: number, value: string) {
   return `${name}=${value}; max-age=${maxAge}; domain=${domain}; path=/`;
}
