import { GoAffProAPI } from './api';
import { GoAffProCookies } from './cookies';

export class GoAffPro {
   private possibleUrlParams = ['ref', 'click_id', 'sub_id', 'wpam_id', 'aff'];
   private goAffProCookies: GoAffProCookies;
   private api: GoAffProAPI;

   constructor() {
      this.goAffProCookies = new GoAffProCookies();
      this.api = new GoAffProAPI();
   }

   static trackVisitIfRequired() {
      const goAffPro = new GoAffPro();
      goAffPro.setRefCodeIfRequired();
      return goAffPro.trackVisitIfRequired();
   }

   getRefCodeFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);

      for (const param of this.possibleUrlParams) {
         const value = urlParams.get(param);
         if (value) {
            return value;
         }
      }

      return null;
   }

   setRefCodeIfRequired() {
      const ref_code = this.getRefCodeFromUrl();

      if (!ref_code) {
         return;
      }

      this.goAffProCookies.setReferralCode(ref_code);
   }

   async trackVisitIfRequired() {
      const ref_code = this.goAffProCookies.getReferralCode();

      if (!ref_code) {
         return;
      }

      const visitor_id = this.goAffProCookies.getVisitorId();

      const data = await this.api.trackVisit({
         id: visitor_id,
         location: window.location.href,
         referrer: document.referrer,
         ref: ref_code,
      });

      if (data.id) {
         this.goAffProCookies.setVisitorId(data.id);
      }
   }
}
