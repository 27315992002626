import { safeLocalStorage } from '@ifixit/utils';

export interface UtmParams {
   utm_medium?: string;
   utm_source?: string;
   utm_campaign?: string;
   utm_content?: string;
   timestamp: number;
}
const ONE_HOUR_IN_MS = 3_600_000; // 1 hour in milliseconds

// This function stores UTM parameters into the browser's localStorage.
export function storeUtmParams(): void {
   const url = new URL(location.href);
   const paramNames: (keyof UtmParams)[] = [
      'utm_medium',
      'utm_source',
      'utm_campaign',
      'utm_content',
   ];
   const utmParams: UtmParams = { timestamp: 0 };
   let hasUtmParams = false;

   paramNames.forEach(param => {
      const paramValue = url.searchParams.get(param);
      if (paramValue) {
         // @ts-expect-error: Dynamic key assignment. TS has no way to know that param is a valid key.
         utmParams[param] = paramValue;
         hasUtmParams = true;
      }
   });

   if (hasUtmParams) {
      utmParams.timestamp = Date.now();

      const curTrackersJson = safeLocalStorage.getItem('utmTrackers');
      const curTrackers: UtmParams[] = curTrackersJson ? JSON.parse(curTrackersJson) : [];

      const newTrackers = curTrackers.some(
         obj =>
            obj.utm_campaign === utmParams.utm_campaign &&
            obj.timestamp > utmParams.timestamp - ONE_HOUR_IN_MS
      )
         ? curTrackers
         : [utmParams];

      safeLocalStorage.setItem('utmTrackers', JSON.stringify(newTrackers));
   }
}

export function addUtmTrackerParam(url: URL, numTrackers = 5) {
   const utmTrackerStr = safeLocalStorage.getItem('utmTrackers');
   if (utmTrackerStr) {
      const utmTrackers = JSON.parse(utmTrackerStr) as UtmParams[];
      utmTrackers.sort((a, b) => b.timestamp - a.timestamp);
      const oneWeekAgo = Date.now() - 1000 * 60 * 60 * 24 * 7;
      const truncatedTrackerStr = JSON.stringify(
         utmTrackers.slice(0, numTrackers).filter(tracker => tracker.timestamp > oneWeekAgo)
      );
      url.searchParams.set('utmTrackers', truncatedTrackerStr);
      safeLocalStorage.setItem('utmTrackers', truncatedTrackerStr);
   }
}
