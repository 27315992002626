export type StorageType = 'localStorage' | 'sessionStorage';

class SafeStorage {
   private static availability: Partial<Record<StorageType, boolean>> = {};

   private type: StorageType;

   constructor(type: StorageType) {
      this.type = type;
   }

   get isAvailable(): boolean {
      const isAvailable = SafeStorage.availability[this.type];
      if (isAvailable !== undefined) {
         return isAvailable;
      }

      const available = storageAvailable(this.type);
      SafeStorage.availability[this.type] = available;
      return available;
   }

   get storage(): Storage | null {
      return this.isAvailable ? globalThis[this.type] : null;
   }

   setItem(key: string, value: string): boolean {
      if (!this.storage) {
         return false;
      }

      this.storage.setItem(key, value);
      return true;
   }

   setJson<T>(key: string, json: T): boolean {
      if (!this.storage) {
         return false;
      }

      this.storage.setItem(key, JSON.stringify(json));
      return true;
   }

   getItem(key: string): string | null {
      if (!this.storage) {
         return null;
      }
      const item = this.storage.getItem(key) || null;

      if (item === null || item === 'null') {
         return null;
      }

      return item;
   }

   getJson<T>(key: string): T | null {
      const json = this.getItem(key);

      if (!json) {
         return null;
      }

      return JSON.parse(json);
   }

   removeItem(key: string): boolean {
      if (!this.storage) {
         return false;
      }

      this.storage.removeItem(key);
      return true;
   }

   key(index: number): string | null {
      if (!this.storage) {
         return null;
      }

      return this.storage.key(index) || null;
   }

   clear(): boolean {
      if (!this.storage) {
         return false;
      }

      this.storage.clear();
      return true;
   }
}

export const safeLocalStorage = new SafeStorage('localStorage');

export const safeSessionStorage = new SafeStorage('sessionStorage');

// Checks if local storage or session storage is available in a safe manner.
// Copied from: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
declare const EdgeRuntime: string;

function storageAvailable(type: StorageType): boolean {
   let storage: Storage | undefined;

   // https://vercel.com/docs/functions/runtimes/edge-runtime#check-if-you're-running-on-the-edge-runtime
   if (typeof EdgeRuntime === 'string') {
      return false;
   }

   try {
      storage = globalThis[type];
      if (!storage) {
         return false;
      }
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
   } catch (e) {
      return (
         e instanceof DOMException &&
         // everything except Firefox
         (e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
         // acknowledge QuotaExceededError only if there's something already stored
         storage?.length !== 0
      );
   }
}
