import { PUSH_POPUP, POP_POPUP } from './constants';

const initialState = {
   popupStack: [],
};

const popupReducer = (state = initialState, action) => {
   switch (action.type) {
      case PUSH_POPUP(): {
         return pushPopup(state, action);
      }
      case POP_POPUP(): {
         return {
            ...state,
            popupStack: state.popupStack.slice(0, -1),
         };
      }
      default: {
         return state;
      }
   }
};

function pushPopup(state, action) {
   if (action.popup.dedup && state.popupStack.find(popup => action.popup.dedup === popup.dedup)) {
      return state;
   }

   return {
      ...state,
      popupStack: [...state.popupStack, action.popup],
   };
}

export default popupReducer;
