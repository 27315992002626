import { captureException } from '@ifixit/sentry';

type VisitorAPIResponse = {
   id: string;
   location: string;
   referrer: string | null;
   ref: string;
};

type VisitorAPIBody = {
   id: string | null;
   location: string;
   referrer: string;
   ref: string;
};

export class GoAffProAPI {
   private goAffProPublicToken = '24dd729060dceb797b948533096b86e5457b5bf921e1610d83d5285e5967b971';
   async trackVisit(body: VisitorAPIBody): Promise<VisitorAPIResponse> {
      const sentryData = {
         tags: {
            ref_code: body.ref,
         },
         contexts: {
            Affiliate: {
               ref_code: body.ref,
            },
            RequestBody: {
               body,
            },
         },
      };

      const init = {
         method: 'POST',
         body: JSON.stringify(body),
         headers: {
            'x-goaffpro-public-token': this.goAffProPublicToken,
            'Content-Type': 'application/json',
         },
      };

      const data: VisitorAPIResponse = await this.fetchAndLogErrors(
         'https://api.goaffpro.com/v1/sdk/track/visit',
         init,
         sentryData
      ).then(resp => resp.json() as Promise<VisitorAPIResponse>);

      return data;
   }

   private fetchAndLogErrors(
      input: RequestInfo,
      init: RequestInit | undefined,
      sentryData: Parameters<typeof captureException>[1]
   ) {
      return fetch(input, init).catch(err => {
         // not much we can do if request fails
         // other than log it to sentry
         captureException(err, sentryData);
         return new Response(null, { status: 500 });
      });
   }
}
