/* eslint-disable unicorn/prefer-global-this */

'use client';
import { useState, useEffect, ReactNode, useCallback } from 'react';
import {
   Box,
   Text,
   Link,
   Button,
   ButtonGroup,
   Stack,
   UnorderedList,
   ListItem,
   ButtonProps,
   useDisclosure,
} from '@chakra-ui/react';
import { _js } from '@ifixit/localize';
import { GTag, setGA4Consent } from '@ifixit/analytics';
import { connect } from 'react-redux';
import { ppmsType, Consents, ConsentType, ButtonText, ShopifyAPI, ShopifyConfig } from './types';

const REJECTED = 0;
const ACCEPTED = 1;
const NOT_SEEN = -1;

declare global {
   interface Window {
      ppms?: ppmsType;
      gtag?: GTag;
      Shopify?: ShopifyAPI;
   }
}

const fallBackText = {
   'buttonText': {
      acceptAll: 'Accept All',
      rejectAll: 'Reject All',
      details: 'Details',
   },
   'intro': (
      <>
         iFixit uses cookies to enhance site functionality and analyze traffic. By using our site,
         you agree to our cookie use and{' '}
         <Link href="/Info/Privacy" size="inherit" textDecoration={'underline'}>
            Privacy Policy.
         </Link>
      </>
   ),
   'details': (
      <>
         <Text m={0}>Our cookies fall under the following categories:</Text>
         <UnorderedList m={0} mt={1} pl={6}>
            <ListItem>Analytics</ListItem>
            <ListItem>Marketing</ListItem>
            <ListItem>Conversion tracking</ListItem>
         </UnorderedList>
      </>
   ),
};

const stateToProps = (state: {
   consentBannerState: { bannerIsOpen: boolean; detailsIsOpen: boolean };
}) => ({
   isOpenOverride: state.consentBannerState.bannerIsOpen,
   showDetails: state.consentBannerState.detailsIsOpen,
});
const dispatchToProps = (
   dispatch: ({ type, value }: { type: string; value: unknown }) => void
) => ({
   setShowBanner: (value: boolean) => dispatch({ type: 'TOGGLE_BANNER', value }),
   setShowDetails: (value: boolean) => dispatch({ type: 'TOGGLE_DETAILS', value }),
});

export const ConsentComponent = connect(
   stateToProps,
   dispatchToProps
)(
   ({
      isOpenOverride,
      showDetails,
      setShowBanner,
      setShowDetails,
      buttonText = fallBackText.buttonText,
      intro = fallBackText.intro,
      details = fallBackText.details,
      inEU,
      shopifyConfig,
   }: {
      isOpenOverride: boolean;
      showDetails: boolean;
      setShowBanner: (val: boolean) => void;
      setShowDetails: (val: boolean) => void;
      buttonText?: ButtonText;
      intro?: ReactNode;
      details?: ReactNode;
      inEU: boolean;
      shopifyConfig: ShopifyConfig;
   }) => {
      const { isOpen: showBanner, onOpen, onClose } = useDisclosure({ isOpen: isOpenOverride });
      const [consents, setConsents] = useState<Consents | null>(null);
      const { ppms, shopify } = getWindowDependencies();

      const closeBanner = () => {
         setShowDetails(false);
         setShowBanner(false);
         onClose();
      };

      const openBanner = useCallback(() => {
         onOpen();
         setShowBanner(true);
      }, [onOpen, setShowBanner]);

      useEffect(() => {
         const { ppms, shopify } = getWindowDependencies();
         if (!ppms) {
            return;
         }
         initializeConsentSettings(ppms, shopify, shopifyConfig, inEU, setConsents, openBanner);

         parseSavedConsentSettings(ppms, setConsents, openBanner);
      }, [openBanner, inEU, shopifyConfig]);

      return showBanner && consents && ppms ? (
         <ConsentBanner
            consents={consents}
            closeBanner={closeBanner}
            ppms={ppms}
            shopify={shopify?.customerPrivacy || null}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            buttonText={buttonText}
            intro={intro}
            details={details}
            shopifyConfig={shopifyConfig}
         />
      ) : null;
   }
);

function ConsentBanner({
   consents,
   closeBanner,
   ppms,
   shopify,
   showDetails,
   setShowDetails,
   buttonText,
   intro,
   details,
   shopifyConfig,
}: {
   consents: Consents;
   closeBanner: () => void;
   ppms: ppmsType;
   shopify: ShopifyAPI['customerPrivacy'] | null;
   showDetails: boolean;
   setShowDetails: (val: boolean) => void;
   buttonText: ButtonText;
   intro: ReactNode;
   details: ReactNode;
   shopifyConfig: ShopifyConfig;
}) {
   useEffect(() => {
      setShowDetails(showDetails);
   }, [setShowDetails, showDetails]);

   function agreeToAll() {
      setStatusForAll(consents, ACCEPTED, ppms, shopify, shopifyConfig, closeBanner);
      ppms.cm.api('trackAgreeToAllClick');
   }

   function rejectAll() {
      setStatusForAll(consents, REJECTED, ppms, shopify, shopifyConfig, closeBanner);
      ppms.cm.api('trackRejectAllClick');
   }

   return (
      <Box
         data-test-id="consent-banner"
         position="fixed"
         left={'50%'}
         bottom={{ base: 6, sm: 3 }}
         transform="translateX(-50%)"
         zIndex="overlay"
         fontSize="md"
         maxWidth="944px"
         width={{ base: 'calc(100vw - 38px)' }}
      >
         <Box
            mx="auto"
            p={6}
            background="white"
            border="1px"
            borderColor="gray.200"
            borderRadius="lg"
            boxShadow="lg"
         >
            <Stack
               mx="auto"
               direction={{ base: 'column', sm: 'row' }}
               alignItems={{ base: 'start', sm: 'center' }}
               spacing={{ base: 2, sm: 3 }}
            >
               <Text m={0} fontSize="inherit">
                  {intro}
               </Text>
               {!showDetails && (
                  <ButtonGroup spacing={{ base: 2, sm: 3 }} size={{ base: 'xs', sm: 'sm' }}>
                     <SecondaryButton
                        onClick={() => setShowDetails(true)}
                        data-test-id="details-button"
                     >
                        {buttonText.details}
                     </SecondaryButton>
                     <PrimaryButton onClick={agreeToAll} data-test-id="accept-all-button">
                        {buttonText.acceptAll}
                     </PrimaryButton>
                  </ButtonGroup>
               )}
            </Stack>
            {showDetails && (
               <Details
                  agreeToAll={agreeToAll}
                  rejectAll={rejectAll}
                  buttonText={buttonText}
                  details={details}
               />
            )}
         </Box>
      </Box>
   );
}

function Details({
   agreeToAll,
   rejectAll,
   buttonText,
   details,
}: { agreeToAll: () => void; rejectAll: () => void; buttonText: ButtonText; details: ReactNode }) {
   return (
      <Box data-test-id="details" mt={{ base: 2, sm: 3 }}>
         <ButtonGroup spacing={{ base: 2, sm: 3 }} size={{ base: 'xs', sm: 'sm' }}>
            <SecondaryButton onClick={rejectAll} data-test-id="reject-all-button">
               {buttonText.rejectAll}
            </SecondaryButton>
            <PrimaryButton onClick={agreeToAll} data-test-id="accept-all-button">
               {buttonText.acceptAll}
            </PrimaryButton>
         </ButtonGroup>
         <Box mt={{ base: 3, sm: 6 }}>{details}</Box>
      </Box>
   );
}

const SecondaryButton = ({ children, ...buttonProps }: { children: ReactNode } & ButtonProps) => (
   <Button color="black" fontSize="inherit" fontWeight="600" background="gray.200" {...buttonProps}>
      {children}
   </Button>
);

const PrimaryButton = ({ children, ...buttonProps }: { children: ReactNode } & ButtonProps) => (
   <Button variant="cta" fontSize="inherit" {...buttonProps}>
      {children}
   </Button>
);

function initializeConsentSettings(
   ppms: ppmsType,
   shopify: ShopifyAPI | null,
   shopifyConfig: ShopifyConfig,
   inEU: boolean,
   setConsents: (_consents: Consents) => void,
   openBanner: () => void
) {
   ppms.cm.api('getNewComplianceTypes', (newConsentTypes: ConsentType[]) => {
      if (newConsentTypes.length === 0) {
         return null;
      }
      ppms.cm.api('setInitialComplianceSettings', { consents: newConsentTypes }, () => {
         const defaultConsents = getDefaultConsents(newConsentTypes);
         if (inEU) {
            ppms.cm.api('trackMainFormView');
            setConsents(defaultConsents);
            openBanner();
         } else {
            setStatusForAll(
               defaultConsents,
               ACCEPTED,
               ppms,
               shopify?.customerPrivacy || null,
               shopifyConfig,
               () => {} // do not close banner since banner is not open
            );
            setConsents(defaultConsents);
         }
      });
   });
}

function parseSavedConsentSettings(
   ppms: ppmsType,
   setConsents: (_consents: Consents) => void,
   openBanner: () => void
) {
   ppms.cm.api('getComplianceSettings', (complianceSettings: { consents: Consents }) => {
      const savedConsents = complianceSettings.consents;
      setConsents(savedConsents);
      for (const consentType in savedConsents) {
         const consent = savedConsents[consentType as ConsentType];
         if (consent.status === NOT_SEEN) {
            openBanner();
            return;
         }
      }
   });
}

function setStatusForAll(
   consents: Consents,
   status: number,
   ppms: ppmsType,
   shopifyApi: ShopifyAPI['customerPrivacy'] | null,
   shopifyConfig: ShopifyConfig,
   successCallback: () => unknown
) {
   for (const consentType in consents) {
      consents[consentType as ConsentType].status = status;
   }
   ppms.cm.api('setComplianceSettings', { consents }, successCallback);
   setGA4Consent({ consent: status === 1, update: true });
   const shopifyConsentValue = status === 1 ? true : false;
   shopifyApi?.setTrackingConsent({
      'marketing': shopifyConsentValue,
      'analytics': shopifyConsentValue,
      'preferences': shopifyConsentValue,
      'sale_of_data': shopifyConsentValue,
      'headlessStorefront': true,
      'checkoutRootDomain': shopifyConfig.checkout,
      'storefrontRootDomain': shopifyConfig.storefront,
      'storefrontAccessToken': shopifyConfig.accessToken,
   });
}

function getDefaultConsents(consentTypes: string[]): Consents {
   const consents = {} as Consents;
   consentTypes.forEach(consentType => {
      consents[consentType as ConsentType] = { status: NOT_SEEN };
   });
   return consents;
}

function getWindowDependencies() {
   if (typeof window === 'undefined') {
      return { ppms: null, shopify: null };
   }
   const shopify = window?.Shopify ?? null;
   if (!window?.ppms?.cm?.api) {
      return { ppms: null, shopify };
   }
   return { ppms: window.ppms, shopify };
}
