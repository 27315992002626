import {
   SetCompletionsAction,
   CompletionsErrorAction,
   CompletionsActionType,
   CompletionMap,
} from './completions.actions';

export interface CompletionsState {
   teamCompletions: CompletionMap;
   userCompletions: CompletionMap;
   courseCompletions: CompletionMap;
   error: string;
}

const initialState: CompletionsState = {
   teamCompletions: [],
   userCompletions: [],
   courseCompletions: [],
   // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
   error: null,
};

type Actions = SetCompletionsAction & CompletionsErrorAction;

export const completionsReducer = (
   state: CompletionsState = initialState,
   action: Actions
): CompletionsState => {
   switch (action.type) {
      case CompletionsActionType.SET_TEAM_COMPLETIONS: {
         return {
            ...state,
            teamCompletions: action.completionPercents,
            // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
            error: null,
         };
      }
      case CompletionsActionType.SET_USER_COMPLETIONS: {
         return {
            ...state,
            userCompletions: action.completionPercents,
            // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
            error: null,
         };
      }
      case CompletionsActionType.SET_COURSE_COMPLETIONS: {
         return {
            ...state,
            courseCompletions: action.completionPercents,
            // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
            error: null,
         };
      }
      case CompletionsActionType.COMPLETIONS_ERROR: {
         return {
            ...state,
            error: action.error,
         };
      }
      default: {
         return state;
      }
   }
};

export const COMPLETIONS_STATE_ROOT = 'completionsState';

export const teamCompletionSelector =
   (teamid: number): ((state: any) => number) =>
   // @ts-expect-error TS(2322) FIXME: Type '(state: any) => number | null' is not assign... Remove this comment to see the full error message
   state => {
      const completionsState: CompletionsState = state[COMPLETIONS_STATE_ROOT];

      if (completionsState.error) {
         return 0;
      }

      const teamCompletions: CompletionMap = completionsState.teamCompletions;
      return !state.error && teamCompletions ? teamCompletions[teamid] : null;
   };

export const userCompletionSelector =
   (userid: number): ((state: any) => number) =>
   // @ts-expect-error TS(2322) FIXME: Type '(state: any) => number | null' is not assign... Remove this comment to see the full error message
   state => {
      const completionsState: CompletionsState = state[COMPLETIONS_STATE_ROOT];

      if (completionsState.error) {
         return 0;
      }

      const userCompletions: CompletionMap = completionsState.userCompletions;
      return !state.error && userCompletions ? userCompletions[userid] : null;
   };

export const courseCompletionSelector =
   (wikiid: number): ((state: any) => number) =>
   // @ts-expect-error TS(2322) FIXME: Type '(state: any) => number | null' is not assign... Remove this comment to see the full error message
   state => {
      const completionsState: CompletionsState = state[COMPLETIONS_STATE_ROOT];

      if (completionsState.error) {
         return 0;
      }

      const courseCompletions: CompletionMap = completionsState.courseCompletions;
      return courseCompletions ? courseCompletions[wikiid] : null;
   };
