import { safeLocalStorage } from './safeStorage';

export interface RecentDevice {
   wikiid: number;
   variantid?: number;
   device: string;
   title: string;
   image_url: string;
}

const MAX_RECENT_DEVICES = 10;

export function addRecentDevice({ wikiid, variantid, device, title, image_url }: RecentDevice) {
   const currentRecentDevices = safeLocalStorage.getJson<RecentDevice[]>('recentDevices') ?? [];
   const newRecentDevices = [
      { wikiid, variantid, device, title, image_url },
      ...currentRecentDevices.filter((device: RecentDevice) => device.wikiid !== wikiid),
   ];
   safeLocalStorage.setJson('recentDevices', newRecentDevices.slice(0, MAX_RECENT_DEVICES));
}
