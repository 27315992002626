import { createSharedState } from 'Shared/use-shared-state';

export const selectedWikiDeviceSlice = createSharedState<null | string>(
   'wikiDevice',
   App.deviceTitle
);
export const useSelectedDevice = selectedWikiDeviceSlice.hook;

export const savingState = createSharedState<boolean>('saving', false);
export const useSavingState = savingState.hook;

export const inputFormValue = createSharedState<string>('inputValue', App.inputFormValue);
export const useInputValue = inputFormValue.hook;
