/* eslint-disable no-console */
type Log<T = unknown> = {
   (...data: T[]): void;
   (message?: T, ...optionalParams: T[]): void;
};

type ExtendedLog<T = unknown> = Log<T> & {
   timing: (statName: string, ms: number) => void;
   event: (statName: string) => void;
};

export interface Logger<T = unknown> {
   info: ExtendedLog<T>;
   success: ExtendedLog<T>;
   warning: ExtendedLog<T>;
   error: ExtendedLog<T>;
}

const colorless = Boolean(process.env.COLORLESS_LOGS);

const colorlessLogger = withExtensions(console.log);

export const log: Logger<string> = colorless
   ? {
        info: colorlessLogger,
        success: colorlessLogger,
        warning: colorlessLogger,
        error: colorlessLogger,
     }
   : {
        info: colorlessLogger,
        success: withExtensions((message?: string, ...optionalParams: string[]) =>
           console.log(`\u001B[32m${message ?? ''} \u001B[0m`, ...optionalParams)
        ),
        warning: withExtensions((message?: string) =>
           console.log(`\u001B[33m${message} \u001B[0m`)
        ),
        error: withExtensions((message?: string) => console.log(`\u001B[31m${message} \u001B[0m`)),
     };

function withExtensions<T = unknown>(logger: Log<T>): ExtendedLog<T> {
   const extendedLog = (message?: T, ...optionalParams: T[]) => logger(message, ...optionalParams);
   extendedLog.timing = (statName: string, ms: number) =>
      logger(`${statName} => ${ms.toFixed(2)}ms` as T);
   extendedLog.event = (eventName: string) => logger(`event ${eventName} -> 1` as T);
   return extendedLog;
}
