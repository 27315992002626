import {
   SetTeamMembersAction,
   TeamMembersActionType,
   TeamMembersErrorAction,
} from './team-members.actions';
import { NewAssignmentCreatedAction, SharedActionType } from './shared.actions';

export interface TeamMembersState {
   [teamid: number]: TeamMembersDetails;
}

export interface TeamMembersDetails {
   members: any[] | null;
   courses: any[] | null;
   error?: any;
}

const initialState: TeamMembersState = {};

type Actions = SetTeamMembersAction | TeamMembersErrorAction | NewAssignmentCreatedAction;

export const teamMembersReducer = (
   state: TeamMembersState = initialState,
   action: Actions
): TeamMembersState => {
   switch (action.type) {
      case TeamMembersActionType.SET_TEAM_MEMBERS: {
         return {
            ...state,
            [action.teamid]: {
               members: action.members,
               courses: action.courses,
               error: null,
            },
         };
      }
      case TeamMembersActionType.TEAM_MEMBERS_ERROR: {
         return {
            ...state,
            [action.teamid]: {
               members: [],
               courses: [],
               error: action.error,
            },
         };
      }
      case SharedActionType.NEW_ASSIGMENT_CREATED: {
         if (!action.assignment.assignee_teamid) {
            return state;
         }

         const teams = { ...state };
         // Remove changed team so it can refresh
         delete teams[action.assignment.assignee_teamid];
         return teams;
      }
      default: {
         return state;
      }
   }
};

export const TEAM_MEMBERS_STATE_ROOT = 'teamMembersState';

export const teamMembersSelector =
   (teamid: number): ((state: any) => TeamMembersDetails) =>
   state => {
      const teamMembersState: TeamMembersState = state[TEAM_MEMBERS_STATE_ROOT];
      return teamMembersState[teamid] || { members: null, courses: null };
   };
