import { SentryError } from '@ifixit/sentry';
import type { ProductID, ProductVariantID } from './type-helpers';

interface Reviewable {
   rating: number;
   count: number;
}

const ITEMCODE_RE = /IF(\d{3})-(\d{3})-(\d{1,2})/;

export function shouldShowProductRating<R extends Reviewable>(
   reviewable: R | null | undefined
): reviewable is R {
   if (reviewable?.rating == null || reviewable?.count == null) {
      return false;
   }
   return reviewable.rating >= 4 || reviewable.count > 10;
}

export function getItemCodeFromSku(sku: string): string {
   const itemCode = `IF${sku.replace(/(.{3})/g, '$1-')}`;
   return itemCode.endsWith('-') ? itemCode.slice(0, -1) : itemCode;
}

export function parseItemcode(itemcode: string) {
   const matches = itemcode.match(ITEMCODE_RE);
   return matches
      ? {
           category: matches[1],
           productcode: matches[1] + matches[2],
           optionid: matches[3],
        }
      : {};
}

export function isLifetimeWarranty(warranty: string | undefined | null): boolean {
   if (!warranty) return false;
   return /lifetime/i.test(warranty);
}

export function getVariantIdFromVariantURI(variantURI: ProductVariantID): string {
   if (!variantURI.startsWith('gid://')) {
      throw new SentryError('Variant URI must be a global shopify product variant id uri');
   }
   return variantURI.replace(/^gid:\/\/shopify\/ProductVariant\//, '');
}

export function getProductIdFromGlobalId(globalProductId: ProductID) {
   if (!globalProductId.startsWith('gid://')) {
      throw new SentryError('globalProductId must be a global shopify product id uri');
   }
   return globalProductId.replace(/^gid:\/\/shopify\/Product\//, '');
}

export function getProductVariantURI(variantId: string | number): ProductVariantID {
   return `gid://shopify/ProductVariant/${variantId}` as ProductVariantID;
}

export function getCategoriesFromTags(tags: string[]): string[] {
   const tagsObj: Record<string, string> = {};
   tags.forEach(tag => {
      const [name, val] = tag.split(/=|:/);
      tagsObj[name] = val;
   });
   const categories = [
      'Tool' in tagsObj ? 'Tool' : 'Part' in tagsObj ? 'Part' : 'N/A',
      tagsObj['Item Category'],
      tagsObj['Part SubCategory'],
   ];
   return categories.map(category => category ?? 'N/A');
}
