import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import API_2_0 from 'Shared/api_2_0';

export enum CompletionsActionType {
   SET_TEAM_COMPLETIONS = 'SET_TEAM_COMPLETIONS',
   SET_USER_COMPLETIONS = 'SET_USER_COMPLETIONS',
   SET_COURSE_COMPLETIONS = 'SET_COURSE_COMPLETIONS',
   COMPLETIONS_ERROR = 'COMPLETIONS_ERROR',
}

export const getTeamCompletions = (teamids: number[]): ThunkAction<any, any, any, Action> =>
   getCompletions(
      teamids,
      'teamids',
      'course_assignments/completion/teams',
      CompletionsActionType.SET_TEAM_COMPLETIONS
   );

export const getUserCompletions = (userids: number[]): ThunkAction<any, any, any, Action> =>
   getCompletions(
      userids,
      'userids',
      'course_assignments/completion/users',
      CompletionsActionType.SET_USER_COMPLETIONS
   );

export const getCourseCompletions = (wikiids: number[]): ThunkAction<any, any, any, Action> =>
   getCompletions(
      wikiids,
      'wikiids',
      'course_assignments/completion/courses',
      CompletionsActionType.SET_COURSE_COMPLETIONS
   );

export interface CompletionMap {
   [id: number]: number;
}

interface CompletionsResponseBody {
   completionPercents: CompletionMap;
}

const getCompletions =
   (
      ids: number[],
      idsKey: 'userids' | 'teamids' | 'wikiids',
      endpoint: string,
      actionType: CompletionsActionType
   ): ThunkAction<any, any, any, Action> =>
   dispatch =>
      new API_2_0(endpoint, { method: 'POST' }).send({ [idsKey]: ids }).then(
         ({ completionPercents }: CompletionsResponseBody) => {
            dispatch(setCompletions(actionType, completionPercents));
         },
         (error: string) => {
            dispatch(completionsError(error));
         }
      );

export interface SetCompletionsAction extends Action {
   completionPercents: CompletionMap;
}

export const setCompletions = (
   actionType: string,
   completionPercents: CompletionMap
): SetCompletionsAction => ({
   type: actionType,
   completionPercents,
});

export interface CompletionsErrorAction extends Action {
   error: string;
}

export const completionsError = (error: any): CompletionsErrorAction => ({
   type: CompletionsActionType.COMPLETIONS_ERROR,
   error,
});
