import Notifications from 'Shared/Notifications/notifications';

type StateType = {
   displayMobileSearch: boolean;
   displayMobileMenu: boolean;
   userTopics: any;
   notifications: Notifications;
   miniCart: {
      products: any[];
   };
};

const initialState: StateType = {
   displayMobileSearch: false,
   displayMobileMenu: false,
   userTopics: null,
   notifications: new Notifications(),
   miniCart: {
      products: [],
   },
};

const headerReducer = (state = initialState, action: any) => {
   switch (action.type) {
      case 'TOGGLE_MOBILE_SEARCH': {
         return {
            ...state,
            displayMobileSearch: action.value,
         };
      }
      case 'UPDATE_WORKBENCH': {
         return {
            ...state,
            userTopics: action.userTopics,
         };
      }
      case 'UPDATE_NOTIFICATIONS': {
         return {
            ...state,
            notifications: action.notifications,
         };
      }
      case 'UPDATE_MINI_CART': {
         return {
            ...state,
            miniCart: action.miniCartInfo,
         };
      }
      case 'TOGGLE_MOBILE_MENU': {
         return {
            ...state,
            displayMobileMenu: !state.displayMobileMenu,
         };
      }
      default: {
         return state;
      }
   }
};

export default headerReducer;
