export function computeDiscountPercentage(
   price: number | Money,
   compareAtPrice?: number | Money | null
): number {
   if (compareAtPrice == null) {
      return 0;
   }
   const priceCents = typeof price === 'number' ? price : convertMoneyToCents(price);
   const compareAtPriceCents =
      typeof compareAtPrice === 'number' ? compareAtPrice : convertMoneyToCents(compareAtPrice);
   let percentage = 100 - (100 * priceCents) / compareAtPriceCents;
   percentage = Math.round(percentage);
   return percentage;
}

function convertMoneyToCents(money: Money): number {
   return moneyToNumber(money) * 100;
}

export type Money<T extends string | number = string | number> = {
   /** Numeric or string representation of money expressed as a decimal number (e.g. 39.99) */
   amount: T;
   /** Country currency code (e.g. usd) */
   currencyCode: string;
};

export function moneyToNumber(money: Money) {
   if (typeof money.amount === 'number') {
      return money.amount;
   }
   return Number.parseFloat(money.amount);
}

/**
 * Formats a Money object into a localized currency string.
 *
 * @param {Money} money - The Money object to format.
 * @param {string} [locale='en-US'] - The locale to use for formatting.
 *   Should be a BCP 47 language tag (e.g., 'en-US', 'fr-FR', 'de-DE').
 *   See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument for more details.
 * @returns {string} The formatted currency string.
 *
 * @example
 * // Returns "$10.00" (assuming USD)
 * formatMoney({ amount: 1000, currencyCode: 'USD' });
 *
 * @example
 * // Returns "10,00 €" (assuming EUR)
 * formatMoney({ amount: 1000, currencyCode: 'EUR' }, 'fr-FR');
 */
export function formatMoney(money: Money, locale: string): string {
   const amount = moneyToNumber(money);
   const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: money.currencyCode,
   });
   return formatter.format(amount);
}

export function multiplyMoney(money: Money, multiplier: number): Money {
   const cents = convertMoneyToCents(money);
   const amount = (cents * multiplier) / 100;
   return {
      ...money,
      amount: amount.toFixed(2),
   };
}

export function sumMoney(moneys: Money[]): Money {
   const cents = moneys.reduce((sum, money) => sum + convertMoneyToCents(money), 0);
   const amount = cents / 100;
   return {
      ...moneys[0],
      amount: amount.toFixed(2),
   };
}

export function parseMoney(amount: number, currencyCode: string = 'usd'): Money {
   return {
      amount: amount,
      currencyCode: currencyCode,
   };
}

export function lessThan(a: Money, b: Money): boolean {
   return convertMoneyToCents(a) < convertMoneyToCents(b);
}
