declare global {
   interface Window {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _paq?: { push: (arr: Array<any>) => void } | Array<any>;
   }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function piwikPush(data: any[]) {
   if (typeof window === 'undefined') {
      return;
   }

   // eslint-disable-next-line @typescript-eslint/no-array-constructor
   const _paq = (window._paq = window._paq || new Array());
   _paq.push(data);
}
